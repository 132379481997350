export default {
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestovní pojištění"])},
  "contact": {
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+420 607 772 573"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "travelinsurance.cz"])},
    "assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistenční služba"])},
      "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+420 296 339 631"])}
    }
  },
  "home": {
    "coverage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestujte bezpečně do celého světa s naším celoročním pojištěním"])},
    "what_is_covered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozsah pojištění"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sjednat online"])},
    "price_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roční pojistné"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.790 Kč"])},
    "faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otázky a odpovědi"])},
    "additional_services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doplňkové pojištení"])},
      "companions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nahlášení spolucestujících"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nahlásit"])}
      },
      "trip_extension": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodloužení<br>pobytu"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodloužit"])}
      },
      "trip_cancel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storno<br>zájezdu"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornovat"])}
      }
    }
  },
  "test": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mám zájem o následující rozsah pojištění"])},
    "invalid": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojištění nesplňuje Vaše požadavky"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= prosím kontaktujte naše Klientské centrum, kde Vám poradíme vhodný produkt přesně pro Vás v pracovní dny od 9:00 - 17:00"])}
    }
  },
  "people": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlášení spolucestujících osob"])}
  },
  "application": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["údaje žádosti"])}
  },
  "order": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
    "person": {
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jméno"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])}
      },
      "surname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["příjmení"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novák"])}
      },
      "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum narození"])},
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priklad", "@", "gmail.com"])}
      },
      "tel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefon"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+420 000 000 000"])}
      },
      "trip_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvání cesty od"])},
      "trip_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvání cesty do"])}
    },
    "addPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ přidat další osobu"])},
    "address": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresa trvalého pobytu"])},
      "street": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulice"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štefánikova"])}
      },
      "street_number": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo domu"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["201"])}
      },
      "town": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["město"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praha"])}
      },
      "zip": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["941 02"])}
      }
    },
    "national_id": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rodné číslo"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napr. 2206109334"])}
    },
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["státní příslušnost"])},
    "id_card": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo občanského průkazu"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S000002"])}
    },
    "action_code": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOD akce"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "insurance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["začátek platnosti pojištění"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slevový kód"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "datepicker": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zvolit"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušit"])}
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zpět"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokračovat"])}
  },
  "gdpr": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vámi zadanou mailovou adresu Vám zasíláme potvrzení o uzavření pojištění, pojistné podmínky a veškeré informační povinnosti a prosíme Vás o jejich prostudování. Úhradou pojistného zároveň prohlašujete, že jste byli úplně, srozumitelně a v dostatečném časovém předstihu seznámeni s jejich zněním a byla Vám vysvětlena všechna ustanovení v nich obsažená.\n"])},
    "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím s elektronickou komunikací, informace související s poskytováním finančních služeb mi budou zaslány na mailovou adresu, kterou jsem zadal. Potvrzuji, že k této schránce elektronické pošty mám pravidelný přístup."])}
  },
  "header": {
    
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole je povinné"])},
    "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodné číslo není validní"])},
    "national_id_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodné číslo není validní nebo věk je vyšší než 70 let"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole musí obsahovat 5 číslic"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo zadávejte ve formátu: +420 000 000 000"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte korektní email"])}
  },
  "payment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platit online kartou"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za provedení platby"])},
    "already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka již byla zaplacena"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba selhala"])},
    "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za vytvoření objednávky. Platbu můžete provést kartou nebo převodem podle pokynů na mailu."])}
  },
  "files": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formuláře"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informace"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlášení škody"])}
  },
  "footer": {
    "all_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o rozsahu pojištění"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formuláře"])},
    "required_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informace"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používáme cookies."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náš web používá cookies. Ty mohou být uloženy až po Vašem souhlasu."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["souhlasím"])}
  },
  "companions": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V ceně pojištění máte zahrnuto pojištění pro 4 další osoby, které průkazně cestují spolu s Vámi stejným dopravním prostředkem, do stejného cíle v zahraničí.<br><br>Pokud máte doklad o společné cestě, není nezbytné osoby nahlašovat – postačí kupříkladu letenka na stejný termín, faktura za pobyt v hotelu, voucher z cestovní kanceláře - které předložíte po vzniku škody spolu s ostatními doklady.<br><br>Pokud doklad o společné cestě nemáte, prosím zadejte údaje o Vašich spolucestujících a termín Vaší cesty. \n\n\n"])},
    "main_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hlavní pojištěný"])},
    "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spolucestující osoby"])}
  },
  "form": {
    "server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická chyba, kontaktujte prosím podporu"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašla se platná smlouva na toto období"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Děkujeme za vyplnění formuláře"])}
  },
  "cancellation": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://online.allianzsp.sk/poistenie-storno-zajazdu/?origin=10300"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie storna pre jednotlivú cestu"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dojednať online"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Poistenie sa vzťahuje na náhradu nákladov za cestovné služby, ktoré nemôžte využiť kvôli zdravotným, rodinným alebo iným nepriaznivým okolnostiam - zoznam príčin je uvedený nižšie.</p><p>Krytá je aj náhrada za nevyužitú cestu - pokiaľ Váš pobyt musíte ukončiť skôr a nemáte nárok na vrátenie už zaplatených služieb = na 1 deň 5% z ceny zájazdu / max. 10 dní</p>"])},
    "also_slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aj pre pobyty na&nbsp;Slovensku"])},
    "trip_price": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cena zájazdu"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celková cena za cestovné náklady = doprava, ubytovanie, trajekt, vstupenky a iné dopredu uhradené služby"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suma"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistné"])},
    "basic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["základný rozsah"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OCHORENIE  poisteného, spolucestujúceho alebo blízkej osoby,\n\nÚRAZ alebo SMRŤ poisteného, spolucestujúceho alebo blízkej osoby,\n\nznačná škoda na majetku - následkom živelnej udalosti alebo trestného činu\n\nzničenie / strata cestovných dokladov menej ako 3 pracovné dni pred cestou\n\npovolávací rozkaz na vojenské cvičenie doručený po uzatvorení poistenia\n\ntehotenstvo zistené po rezervácii cesty "])}
    },
    "premium": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozšírený rozsah"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAVIAC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zmeškanie nástupu do dopravného prostriedku kvôli dopravnej nehode alebo poruche vlaku\nkomplikácie v mieste kam cesta smeruje - živelná udalosť, vojna, teroristický čin - po úradne vydanom odporúčaní necestovať do oblasti\nvodovodná škoda v mieste bydliska menej ako 5 dní pred cestou\n\nstrata pracovného miesta - výpoveď zo strany zamestnávateľa, nie však pre porušenie pracovnej disciplíny\npodanie žiadosti o rozvod\nneočakávané súdne predvolanie - pokiaľ súd neakceptuje cestu ako dôvod pre odloženie\nzamietnutie žiadosti o vízum, nie však kvôli neskorému podaniu alebo formálnym chybám"])}
    },
    "co-participation": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spoluúčasť 10%"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10%"])}
    },
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie je potrebné dojednať do 24 hodín od zaplatenia prvej splátky alebo rezervácie cesty.\n Pri dojednaní neskôr sa uplatní čakacia lehota 14 dní od dojednania poistenia."])}
  },
  "extension": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodloužení platnosti pojištění pro pobyt delší než 90 dní"])},
    "daysCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet dní"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cena"])},
    "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum od"])},
    "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datum do"])},
    "bottomInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Vámi zadanou e-mailovou adresu Vám zasíláme potvrzení o prodloužení pojištění, které je účinné nejdříve dnem přijetí platby, proto Vás prosíme o úhradu neprodleně"])}
  }
}