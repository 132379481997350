export default {
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestovné poistenie"])},
  "contact": {
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0917 858 738"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "travelinsurance.sk"])},
    "assistance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistenčná služba"])},
      "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ 420 296 339 631"])}
    }
  },
  "home": {
    "coverage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestujte bezpečne do celého sveta s naším celoročným poistením"])},
    "what_is_covered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozsah poistenia"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dojednať online"])},
    "price_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ročné poistné"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["79 €"])},
    "faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otázky a odpovede"])},
    "additional_services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doplnkové poistenie"])},
      "companions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nahlásenie<br>spolucestujúcich"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nahlásiť"])}
      },
      "trip_extension": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predĺženie<br>pobytu"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predĺžiť"])}
      },
      "trip_cancel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storno<br>zájazdu"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornovať"])}
      },
      "car": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistencia pre&nbsp;motoristov"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornovať"])}
      },
      "bike": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistencia pre&nbsp;cyklistov"])},
        "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornovať"])}
      },
      "short_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["krátkodobé<br>poistenie"])},
      "park": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistenie<br>do hôr"])},
      "golf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["golfové<br>poistenie"])},
      "ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistenie<br>lodí"])},
      "golf_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://golfinsurance.sk/"])}
    }
  },
  "test": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mám záujem o nasledovný rozsah poistenia"])},
    "invalid": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie nespĺňa Vaše požiadavky"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= prosím kontaktujte naše Klientske centrum, kde Vám poradíme vhodný produkt presne pre Vás v pracovné dni od 9:00 - 17:00 "])}
    },
    "cancellation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie storna nie je súčasťou celoročného poistenia, je možné ho dojednať samostatne pre jednotlivú cestu."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dojednať poistenie storna"])}
    },
    "car": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenčné služby k motorovému vozidlu nie sú súčasťou celoročného poistenia, je možné ich dojednať samostatne pre jednotlivú cestu."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mám záujem"])}
    },
    "bike": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenčné služby pre cyklistov nie sú súčasťou celoročného poistenia, je možné ich dojednať samostatne pre jednotlivú cestu."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mám záujem"])}
    }
  },
  "people": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásenie spolucestujúcich osôb"])}
  },
  "application": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje žiadosti"])}
  },
  "order": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
    "person": {
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ján"])}
      },
      "surname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priezvisko"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novák"])}
      },
      "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dátum narodenia"])},
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priklad", "@", "gmail.com"])}
      },
      "tel": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["telefón"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 000 000 000"])}
      },
      "trip_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvanie cesty od"])},
      "trip_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trvanie cesty do"])}
    },
    "addPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ pridať ďalšiu osobu"])},
    "address": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adresa trvalého pobytu"])},
      "street": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulica"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulica"])}
      },
      "street_number": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo domu"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["201"])}
      },
      "town": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesto"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesto"])}
      },
      "zip": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["941 02"])}
      }
    },
    "national_id": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rodné číslo"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napr. 2206109334"])}
    },
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["štátna príslušnosť"])},
    "id_card": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo občianskeho preukazu"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S000002"])}
    },
    "action_code": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KOD akcie"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "insurance_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["začiatok platnosti poistenia"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zľavový kód"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "datepicker": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zvoliť"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zrušiť"])}
  },
  "navigation": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naspäť"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pokračovať"])}
  },
  "gdpr": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vami zadanú mailovú adresu Vám posielame potvrdenie o uzatvorení poistenia, poistné podmienky a všetky informačné povinnosti a prosíme Vás o ich preštudovanie. Úhradou poistného zároveň prehlasujete, že  ste boli úplne, zrozumiteľne a v dostatočnom časovom predstihu oboznámený s ich znením a boli Vám vysvetlené všetky ustanovenia v nich obsiahnuté.\n"])},
    "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhlasím s elektronickou komunikáciou, informácie súvisiace s poskytovaním finančných služieb mi budú zaslané na mailovú adresu, ktorú som zadal. Potvrdzujem, že k tejto schránke elektronickej pošty mám pravidelný prístup."])}
  },
  "header": {
    
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole je povinné"])},
    "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodné číslo nie je validne"])},
    "national_id_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodné číslo nie je validne alebo vek je vyšší ako 80 rokov"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole musi obsahovať 5 číslic"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo zadávajte vo formáte: +421 000 000 000"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte korektný email"])}
  },
  "payment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť online kartou"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za vykonanie platby"])},
    "already_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka už bola zaplatená"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba zlyhala"])},
    "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za vytvorenie objednávky. Platbu možete vykonať kartou alebo prevodom podla pokynov na maili."])}
  },
  "files": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o poistení"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlásenie škody"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informácie"])}
  },
  "footer": {
    "all_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o rozsahu poistenia"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlačivá na stiahnutie"])},
    "required_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné informácie"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používame súbory cookie."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naša webová stránka používa súbory cookie. Tie sa môžu uložiť len po vašom súhlase."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["súhlasím"])}
  },
  "companions": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V cene poistenia máte zahrnuté poistenie pre 4 ďalšie osoby, ktoré preukázateľne cestujú spolu s Vami rovnakým dopravným prostriedkom do rovnakého cieľa v zahraničí.<br><br>Pokiaľ máte doklad o spoločnej ceste, nie je potrebné osoby nahlasovať – stačí napríklad letenka na rovnaký termín, faktúra za pobyt v hoteli, voucher z cestovnej kancelárie - ktoré predložíte v prípade vzniku škody spolu s ostatnými dokladmi.<br><br>Ak doklad o spoločnej ceste nemáte, prosím zadajte údaje o Vašich spolucestujúcich a dĺžke trvania cesty."])},
    "main_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hlavný poistený"])},
    "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spolucestujúce osoby"])}
  },
  "form": {
    "server_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická chyba, kontaktujte prosím podporu"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenasla sa platna zmluva na toto obdobie"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za vyplnenie formulára"])}
  },
  "cancellation": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://online.allianzsp.sk/poistenie-storno-zajazdu/?origin=10300"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie storna pre jednotlivú cestu"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dojednať online"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Poistenie sa vzťahuje na náhradu nákladov za cestovné služby, ktoré nemôžte využiť kvôli zdravotným, rodinným alebo iným nepriaznivým okolnostiam - zoznam príčin je uvedený nižšie.</p><p>Krytá je aj náhrada za nevyužitú cestu - pokiaľ Váš pobyt musíte ukončiť skôr a nemáte nárok na vrátenie už zaplatených služieb = na 1 deň 5% z ceny zájazdu / max. 10 dní</p>"])},
    "also_slovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aj pre pobyty na&nbsp;Slovensku"])},
    "trip_price": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cena zájazdu"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["celková cena za cestovné náklady = doprava, ubytovanie, trajekt, vstupenky a iné dopredu uhradené služby"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suma"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poistné"])},
    "basic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["základný rozsah"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OCHORENIE  poisteného, spolucestujúceho alebo blízkej osoby,\n\nÚRAZ alebo SMRŤ poisteného, spolucestujúceho alebo blízkej osoby,\n\nznačná škoda na majetku - následkom živelnej udalosti alebo trestného činu\n\nzničenie / strata cestovných dokladov menej ako 3 pracovné dni pred cestou\n\npovolávací rozkaz na vojenské cvičenie doručený po uzatvorení poistenia\n\ntehotenstvo zistené po rezervácii cesty "])}
    },
    "premium": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozšírený rozsah"])},
      "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAVIAC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zmeškanie nástupu do dopravného prostriedku kvôli dopravnej nehode alebo poruche vlaku\nkomplikácie v mieste kam cesta smeruje - živelná udalosť, vojna, teroristický čin - po úradne vydanom odporúčaní necestovať do oblasti\nvodovodná škoda v mieste bydliska menej ako 5 dní pred cestou\n\nstrata pracovného miesta - výpoveď zo strany zamestnávateľa, nie však pre porušenie pracovnej disciplíny\npodanie žiadosti o rozvod\nneočakávané súdne predvolanie - pokiaľ súd neakceptuje cestu ako dôvod pre odloženie\nzamietnutie žiadosti o vízum, nie však kvôli neskorému podaniu alebo formálnym chybám"])}
    },
    "co-participation": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spoluúčasť 10%"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10%"])}
    },
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie je potrebné dojednať do 24 hodín od zaplatenia prvej splátky alebo rezervácie cesty.\n Pri dojednaní neskôr sa uplatní čakacia lehota 14 dní od dojednania poistenia."])}
  },
  "extension": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predĺženie platnosti poistenia pre pobyt v zahraničí dlhší ako 90 dní"])},
    "daysCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["počet dní"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cena"])},
    "date_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dátum od"])},
    "date_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dátum do"])},
    "bottomInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Vami zadanú mailovú adresu Vám posielame potvrdenie o predĺžení poistenia, poistenie je platné najskôr odo dňa prijatia platby, preto Vás prosíme o úhradu bezodkladne "])}
  },
  "car": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenčné služby k motorovému vozidlu pre jednotlivú cestu"])},
    "gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Vami zadanú mailovú adresu Vám zašleme poistnú zmluvu, spolu s kompletnými poistnými podmienkami. Detailné info k rozsahu poistenia máte k dispozícii aj na stránke Informácie o rozsahu poistenia - v spodnej lište."])},
    "coverage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["územný rozsah"])},
      "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Európa"])},
      "cz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česko"])}
    },
    "vehicle_type": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typ vozidla"])},
      "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vozidlo do 3,5 t"])},
      "motorcycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["motocykel"])}
    },
    "car_licence_number": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŠPZ"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX000XX"])}
    },
    "maintenance_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vozidlo je spôsobilé na premávku na pozemných komunikáciách a absolvovalo pravidelnú servisnú údržbu podľa pokynov výrobcu"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mám záujem"])}
  },
  "bike": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistenčné služby pre cyklistov"])}
  },
  "drone": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie dronov"])}
  }
}